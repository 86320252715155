.center {
    text-align: center;
    margin: auto;
    width: 100vW;
    height: 100vh; 
    margin: 0px;
    background-color: #fff;
}

.logo {
    text-align: center;
    margin: auto;
}