.bodyHeader{
    text-align: center;
    font-size: 36px;
    margin: 0;
    padding-top: 25px;
    padding-bottom: 25px;
}
.body {
    font-size: 18px;
}
.center {
    text-align: center;
    margin: auto;
    width: auto;
    height: 100vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form {
    @media only screen and (min-width: 900px){
        max-width: 40%;
    }  
}

.logo {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 50px;
    width: 200px;
}

.submitButton.ant-btn-primary {
    padding: 15px 10px 13px;
    background-color: #ACE3FF;
    font-size: 12px;
    font-family: FuturaBold;
    background: #ACE3FF;
}

.ButtonText {
    color: #000;
}


.consent {
    text-align: left;
    display: flex;
}

.AntForm {
    margin: 5%;
    width: "50%";
}

:global .ant-input {
    min-height: 34px;
}

:global .ant-picker-input {
    background: white;
}
:global .ant-picker {
    background: white;
    width: -webkit-fill-available;
}

@font-face {
	font-family: 'FuturaBold';
	src: url('../../../assets/fonts/FuturaStd-Bold.otf'); 
	src: 
		local('Futura Bold'),
		local('Futura Bold'),
		url('../../../assets/fonts/FuturaStd-Bold.otf') 
		format('opentype');
}