.center {
    text-align: center;
    margin: auto;
    width: 100vW;
    // height: 100vh; 
    margin: 0px;
    background-color: #fff;
}

.logo {
    text-align: center;
    margin: auto;
}

// .email {
//     font-size: 5vw;

// }

.email { 
    font-size: clamp(4px, 5vw, 4rem);
 }


// .email {
//     font-size: calc(15px + 0.390625vw);
// }