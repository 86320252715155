body * {
  color: #000000;// #ffffff;
  background-color: #ffffff;//#263040;
  margin: 0;
}

:global(#bodytext) {
  font-size: 18px;
  color: #000000;//#ffffff;
  font-family: FuturaRegular;
}

:global(#welcomeText) {
  font-size: 18px;
  color: #000000;// #ffffff;
  font-family: FuturaBold;
}

:global(#emailText) {
  color: #000000;// #ffffff;
  font-family: FuturaBold;
}

:global(#disclaimer) {
  font-size: 12px;
  color: #000000;// #ffffff;
  font-family: FuturaRegular;
}

:global(#completeDisclaimer) {
  font-size: 12px;
  color: #000000;//#ffffff;
  font-family: FuturaRegular;
}

:global(#headline) {
  font-size: 36px;
  color: #000000;// #ffffff;
  font-family: FuturaBold;
}

:global(#buttonText) {
  font-size: 14px;
  color: #000000;
  font-family: FuturaBold;


}

:global(#button) {
  padding: 15px 48px 13px;
  // background-color: #ACE3FF;
  // border: #fff;
  // background: #ACE3FF;
  line-height: 0px;
}

@font-face {
	font-family: 'FuturaRegular';
	src: url('./assets/fonts/FuturaStd-Medium.otf'); 
	src: 
		local('FuturaRegular'),
		local('FuturaRegular'),
		url('./assets/fonts/FuturaStd-Medium.otf') 
		format('opentype');
}

@font-face {
	font-family: 'FuturaBold';
	src: url('./assets/fonts/FuturaStd-Bold.otf'); 
	src: 
		local('FuturaBold'),
		local('FuturaBold'),
		url('./assets/fonts/FuturaStd-Bold.otf') 
		format('opentype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #263040;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
